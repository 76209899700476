import React from 'react';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';

export default () => (
  <Layout>
    <Navigation fixed />
    <div className="section">
      <div className="container is-max-desktop">
        <h3 className="subtitle">fakeOS</h3>
        <h2 className="title">Datenschutzrichtlinien</h2>

        <p>
          fakeOS nimmt Ihren Datenschutz sehr ernst und behandelt Ihre
          personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
          Vorschriften.
        </p>
        <p>
          Die Nutzung dieser Website ist in der Regel ohne Angabe
          personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <p>
          Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
          <br />
          fakeOS
          <br />
          Domstr. 91
          <br />
          50668 Köln
          <br />
          Telefon: +49 177 6170775
          <br />
          E-Mail: <a href="mailto:support@fakeos.de">support@fakeos.de</a>
        </p>
        <h3>Persönliche Daten</h3>
        <p>
          Persönliche Daten, die Sie über diese Website an uns übermitteln (z.B.
          Name, Postanschrift, E-Mail-Adresse), werden nur zum jeweils
          angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte
          weitergegeben.
        </p>

        <p>
          Personenbezogene Daten werden ausschließlich für interne Zwecke
          erhoben und gespeichert, um die von uns angebotenen Leistungen
          erbringen zu können.
        </p>
        <h3>Widerruf, Auskunft, Berichtigung und Löschung</h3>
        <p>
          Die Einwilligung in die Nutzung Ihrer Daten können Sie jederzeit
          widerrufen. Dafür schicken Sie bitte eine Mail an{' '}
          <a href="mailto:support@fakeos.de">support@fakeos.de</a>.
        </p>
        <p>Ihre Daten werden dann umgehend gelöscht.</p>
        <p>
          Sie haben das Recht, Auskunft darüber zu verlangen, welche Daten (z.B.
          Name, Anschrift etc.) über Sie bei uns gespeichert sind. Bitte richten
          Sie Ihre Anfrage schriftlich an:
        </p>
        <p>
          fakeOS / I Love Computers &amp; Internet
          <br />
          Domstr. 91
          <br />
          50668 Köln
        </p>
        <h3>Cookies</h3>
        <p>Auf dieser Website verwenden wir Cookies.</p>
        <p>
          Cookies sind Text-Dateien, in denen Daten auf dem Rechner des Benutzer
          gespeichert werden. Cookies dienen hier dazu, einen geschützten
          Bereich "Mein fakeOS" zu bieten. Für die sonstige Nutzung dieser
          Website, werden keine Cookies verwendet.
        </p>
        <p>
          Die meisten der von uns verwendeten Cookies sind so genannte
          „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
          gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis
          Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser
          beim nächsten Besuch wiederzuerkennen.
        </p>
        <p>
          Wenn Sie in Ihrem Browser die Nutzung von Cookies deaktiviert haben,
          werden auch keine Cookies verwendet und gespeichert.
        </p>
        <h3>Server-Log-Files</h3>
        <p>
          Der Provider dieser Website erhebt und speichert automatisch
          Informationen in so genannten Server-Log-Files, die Ihr Browser
          automatisch an uns übermittelt.
        </p>
        <p>Diese sind:</p>
        <ul>
          <li>das Datum und Uhrzeit der Anfrage</li>
          <li>die Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
          <li>Ihre derzeit verwendete IP-Adresse</li>
          <li>die URL Ihrer Anfrage</li>
          <li>den Zugriffsstatus/HTTP-Statuscode</li>
          <li>die jeweils übertragene Datenmenge</li>
          <li>die Website, von der die Anforderung kommt (Referrer)</li>
          <li>Ihr Betriebssystem und dessen Oberfläche</li>
          <li>die Sprache und Version der Browsersoftware.</li>
        </ul>
        <p>
          Diese Daten sind nicht bestimmten Personen zuordenbar und werden
          getrennt von personenbezogenen Daten gespeichert. Eine Zusammenführung
          dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Diese
          Daten werden zur Gefahrenabwehr im Falle von Angriffen auf unser
          System benötigt. Wir behalten uns vor, diese Daten nachträglich zu
          prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung
          bekannt werden.
        </p>

        <p>
          Der Betreiber dieser Website gibt erhobene Daten nicht an Dritte
          weiter.
        </p>
        <p>
          Alle Daten werden unter Beachtung der einschlägigen Vorschriften der
          Datenschutz-Grundverordnung (DSGVO) und des
          Teledienstdatenschutzgesetzes (TDDSG) von uns gespeichert und
          verarbeitet.
        </p>
        <h3>Tools und Plugins</h3>

        <h3>SSL-Verschlüsselung</h3>
        <p>
          Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel der
          Bewerbungsdaten, die Sie an uns als Veranstalter senden, eine
          SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass in der Adresszeile des Browsers das Protokoll "https://"
          statt "http://" angezeigt wird.
        </p>
        <p>
          Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie
          an uns übermitteln, nicht von Dritten mitgelesen werden.
        </p>
        <h3>Kontaktmöglichkeiten</h3>
        <p>
          Diese Website enthält aufgrund der gesetzlichen Vorschriften
          Kontaktdaten, die es dem Nutzer erlauben, auf einfache Art mit uns in
          Kontakt zu treten. Erfolgt diese Kontaktaufnahme auf elektronischem
          Weg (z.B. per E-Mail), werden diese personenbezogenen Daten bei uns
          automatisch gespeichert. Diese Kontaktdaten übermitteln Sie uns auf
          freiwillger Basis. Die Daten werden nur zum Zweck der Bearbeitung, der
          von Ihnen gewünschten Kontaktaufnahme gespeichert und werden nicht an
          Dritte weitergegeben.
        </p>
      </div>
    </div>
  </Layout>
);
